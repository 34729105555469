import { CardLike } from '@beunity/design';
import { requirePhoneConfirmation } from 'lib/require_phone_confirmation';
import requireCurrentUser from 'lib/require_current_user';

CardLike.prototype.shouldPreventAction = () => requireCurrentUser() || requirePhoneConfirmation();

export default CardLike;

// Hack for Rails preview:
// customElements.get('bu-card-like') || customElements.define('bu-card-like', CardLike);
