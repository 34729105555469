import I18n from 'i18n';
import DataBinder from 'lib/data_binder';

function CommentSchema(d) {
  let text;
  let count;

  if (this.$('.self .js-comment-reply')) {
    if (String(d.replies_count).match(/\+|-/)) {
      text = this.$('.self .js-comment-reply').innerText;
      count = parseInt(text, 10) || 0;
      d.replies_count = count + parseInt(d.replies_count, 10);
    }
  }

  if (d.isLiked === 'toggle') {
    text = this.$('.js-comment-like').innerText;
    count = parseInt(text, 10) || 0;

    d.isLiked = !this.hasClass('.js-comment-like', 'active');
    d.card_comment_likes_count = count + (d.isLiked ? +1 : -1);
    d.card_comment_likes_count < 0 && (d.card_comment_likes_count = 0);
  }

  if ('card_comment_likes_count' in d) {
    d.card_comment_likes_count = I18n.t('card.like', { count: d.card_comment_likes_count });
  }

  if ('replies_count' in d) {
    d.hasComments = d.replies_count >= 1;
    d.replies_count = I18n.t('card.comment.reply', { count: d.replies_count });
  }

  if ('body' in d) {
    d.body += ' ';
  }

  this
    .toggleClass('.js-comment-like', 'active', d.isLiked)
    .toggleClass('.self .js-comment-reply', 'active', d.hasComments)
    .toggleClass('.self', 'edited', d.is_edited)
    .toggleClass('.self', 'deleted', d.deleted_at)
    .html('.js-comment-like', d.card_comment_likes_count)
    .text('.js-comment-reply', d.replies_count)
    .html('.card-comment-body', d.body);
}

export default DataBinder.create(CommentSchema);
