import $ from 'jquery';
import I18n from 'i18n';

$(document)
  .on('change', 'input[type="file"]', function onChangeFileUpload() {
    const { files } = this;
    const $this = $(this);

    // IE11 triggers change on set and clear so we need return if no file
    if (!files) { return; }

    $this.siblings('.js-file-name').html(files.length > 1 ? `${files.length} ${I18n.t('gallery.file_selected', { count: files.length })}` : files[0].name);
    $this.siblings('.js-clear-file').show();
    $this.closest('form').addClass('file-attached');
    $this.trigger('elementresize');
  })

  // RESET FILE INPUT
  .on('click', '.js-clear-file', function onClickFileUpload() {
    const $this = $(this);

    $this.siblings('input[type="file"]').val('').trigger('elementresize');
    $this.siblings('.js-file-name').html('');
    $this.closest('form').removeClass('file-attached');
    $this.hide();
  })

  .on('reset', 'form', function onResetFileUpload() {
    $('.js-clear-file', this).click();
  })

  // SHOULD DELETE FILE / single file
  .on('click', '.js-delete-file-btn', function onClickFileUploadDelete(e) {
    e.preventDefault();

    $('.js-should-delete-file', $(this).closest('.file-upload-wrapper')).val('1');
    $(this).closest('.file-preview').remove();
  })

  // REMOVE files
  .on('click', '.js-remove-file', function onClickFileUploadDelete(e) {
    e.preventDefault();

    const file = $(this);
    const removeFilesIds = $('.js-remove-files', file.closest('.file-upload-wrapper'));
    const val = removeFilesIds.val();
    const ids = (val && val.split(',')) || [];

    ids.push(file.data('id'));
    removeFilesIds.val(ids.join(','));

    file.closest('.file-preview').remove();
  });
