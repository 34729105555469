import './customize/dot_menu_config';

// https://webpack.js.org/guides/dependency-management/#context-module-api

function importAll(r) {
  const elements = r.keys().filter(k => k.split('/').length === 3);
  // Only first level of avatar/element.js
  elements.forEach(r);
}

importAll(require.context('./components', true, /element\.js/));
