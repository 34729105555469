import { AppConfig } from 'src/configuration';
import Turbolinks from 'turbolinks';

export default function requireCurrentUser() {
  if (!AppConfig.user.id) {
    Turbolinks.visit('/login');
    return true;
  }
  return false;
}
