import { AppConfig } from 'src/configuration';

export const renderPhoneConfirmationModal = (urlPath = '/phone/edit') => {
  $.ajax({
    url: urlPath,
    dataType: 'html',
    success: (data) => {
      App.Modal('phone', data);
    },
  });
};

export const requirePhoneConfirmation = (urlPath = '/phone/edit') => {
  if (AppConfig.user.isConfirmed) { return false; }

  renderPhoneConfirmationModal(urlPath);

  return true;
};
