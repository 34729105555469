const isUndef = arg => typeof arg === 'undefined';

class DataBinder {
  constructor(selector, fn, data) {
    if (selector.jquery) {
      this.el = selector.get(0);
    } else {
      this.el = selector.tagName ? selector : document.querySelector(selector);
    }
    if (!this.el) { return; }

    this.el.data || (this.el.data = {});

    const self = this.el.data.dataBinder;

    if (self) {
      data && self.update(data);
    } else {
      this.fn = fn;
      this.cache = {};
      data && this.update(data);
      this.el.data.dataBinder = this;
    }
  }

  update(data) {
    this.fn.call(this, data);
    this.el.dispatchEvent(
      new Event('content:update', { bubbles: true, cancelable: true }),
    );
  }

  $(selector) {
    let element;

    if (selector === ':root') {
      element = this.el;
    } else {
      element = this.cache[selector] || this.el.querySelector(selector);
    }
    this.cache[selector] = element;
    return element;
  }

  text(selector, value) {
    if (isUndef(value) || !this.$(selector)) { return this; }
    this.$(selector).innerText = value; return this;
  }

  isChecked(selector) {
    if (!this.$(selector)) { return false; }
    return this.$(selector).checked;
  }

  html(selector, value) {
    if (isUndef(value) || !this.$(selector)) { return this; }
    this.$(selector).innerHTML = value; return this;
  }

  attr(selector, attr, value) {
    if (isUndef(value) || !this.$(selector)) { return this; }
    const el = this.$(selector); let
      attributes = attr;
    if (arguments.length === 3) {
      attributes = {};
      attributes[attr] = value;
    }

    /* eslint-disable */
    for (const k in attributes) {
      el.setAttribute(k, attributes[k]);
    }
    /* eslint-enable */

    return this;
  }

  data(selector, attr, value) {
    return this.attr(selector, `data-${attr}`, value);
  }

  addClass(selector, value) {
    if (isUndef(value) || !this.$(selector)) { return this; }
    this.$(selector).classList.add(value); return this;
  }

  removeClass(selector, value) {
    if (isUndef(value) || !this.$(selector)) { return this; }
    this.$(selector).classList.remove(value); return this;
  }

  hasClass(selector, value) {
    if (isUndef(value) || !this.$(selector)) { return this; }
    return this.$(selector).classList.contains(value);
  }

  addPart(selector, value) {
    if (isUndef(value) || !this.$(selector)) { return this; }
    this.$(selector).part.add(value); return this;
  }

  removePart(selector, value) {
    if (isUndef(value) || !this.$(selector)) { return this; }
    this.$(selector).part.remove(value); return this;
  }

  hasPart(selector, value) {
    if (isUndef(value) || !this.$(selector)) { return this; }
    return this.$(selector).part.contains(value);
  }

  hasText(selector, value) {
    if (isUndef(value) || !this.$(selector)) { return this; }
    return this.$(selector).innerText === value;
  }

  getText(selector) {
    return this.$(selector).innerText;
  }

  getInteger(selector) {
    return parseInt(this.getText(selector), 10) || null;
  }

  toggleClass(selector, value, condition) {
    if (isUndef(value) || !this.$(selector)) { return this; }
    let finalCondition;
    if (arguments.length === 3) {
      if (isUndef(condition)) { return this; }
      finalCondition = !condition;
    } else {
      finalCondition = this.hasClass(selector, value);
    }
    finalCondition ? this.removeClass(selector, value) : this.addClass(selector, value);
    return this;
  }

  togglePart(selector, value, condition) {
    if (isUndef(value) || !this.$(selector)) { return this; }
    let finalCondition;
    if (arguments.length === 3) {
      if (isUndef(condition)) { return this; }
      finalCondition = !condition;
    } else {
      finalCondition = this.hasPart(selector, value);
    }
    finalCondition ? this.removePart(selector, value) : this.addPart(selector, value);
    return this;
  }

  // Usage Example
  // ===============================================
  // var Article = new DataBinder('article', function(data){
  //   this
  //     .text('h1', data.title)
  //     .attr('h1', {title: data.title, name: data.title})
  //     .toggleClass('h1', 'hello')
  //     .addClass('h1', 'active')
  // });

  // Article.update({title: 'hello2'})
}

DataBinder.create = schema => (selector, data) => new DataBinder(selector, schema, data);

export const DataBinderMapper = (target, map) => {
  let data = {};
  const l = target.classList;

  Object.entries(map).forEach(([k, newData]) => {
    if (l.contains(k)) {
      data = { ...newData, ...data };
    }
  });

  return data;
};

export default DataBinder;
