import I18n from 'i18n';
import Snack from '@effectivastudio/snackbar';
import Turbolinks from 'turbolinks';
import TurboNativeAdapter from '@effectivastudio/turbo_native_adapter';

const Mobile = TurboNativeAdapter;
const { IS_MOBILE } = Mobile;

function resolveRedirectUrl(url) {
  // eslint-disable-next-line compat/compat
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(r => r.json()).then(json => json.Location || json.location);
}

// function isImage(url) {
//   return /\.(png|jpg|jpeg|gif)/.test(url);
// }

function isPdf(url) {
  return /\.(pdf)/.test(url);
}

function extractFilenameFromUrl(url) {
  const encoded = decodeURI(url).match(/filename.*?UTF-8''(.*?)&/)[1];
  return decodeURIComponent(encoded);
}

// eslint-disable-next-line import/prefer-default-export
export function downloadFileOld(e) {
  if (IS_MOBILE) { e.preventDefault(); }

  const { currentTarget } = e;
  const isDownloadLink = currentTarget.href.includes('/download/');

  const getUrl = resolveRedirectUrl(currentTarget.href);

  if (isDownloadLink) {
    getUrl.then((location) => {
      IS_MOBILE && isPdf(location)
        ? Turbolinks.visit(location)
        : Mobile.downloadImage(location);
    });
  } else {
    Mobile.downloadImage(e.currentTarget.href);
  }

  Snack({ text: I18n.t('snack.downloading') });
}

export function downloadFileNew(e) {
  if (!IS_MOBILE) {
    Snack({ text: I18n.t('snack.downloading') });
    return;
  }

  e.preventDefault();

  resolveRedirectUrl(e.currentTarget.href).then((location) => {
    const filename = extractFilenameFromUrl(location);
    Mobile.preview(location, filename);
  });
}

export const downloadFile = Mobile.preview ? downloadFileNew : downloadFileOld;

$(document).on('click', '[href*="/download/"], .js-download-image, .iv-fullscreen-download', downloadFile);
