import { ApplicationElement, SocialShare } from '@beunity/design';
import I18n from 'i18n';
import pusher from 'src/newpusher';
import { setTrixTranslations } from '@beunity/design/components/rte/lib/setup_trix.js';
import DateFinderOption from '@beunity/design/components/date_finder_option/element';
import { AppConfig } from 'src/configuration';

// Copy settings from platform to ApplicationElement
ApplicationElement.i18n = I18n;
ApplicationElement.pusher = pusher;

Object.defineProperty(ApplicationElement.prototype, 'AppConfig', { get() { return AppConfig; } });

SocialShare.icons = AppConfig.env.SHARE_ICONS;

DateFinderOption.currentUser = () => ({
  name: AppConfig.user.fullName,
  picture: AppConfig.user.avatar,
});

setTrixTranslations();
I18n.onChange(() => {
  setTrixTranslations();
});
