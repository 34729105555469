import DataBinder from 'lib/data_binder';

function OrganizeSchema(d) {
  if ('option_sum_item_amounts' in d) {
    const amount = d.option_sum_item_amounts;
    const result = d.goal > 0 ? `${amount} / ${d.goal}` : amount;

    this.text('.js-organize-item-assignees-count', result);
  }

  if ('option_avatars' in d) {
    this.html('.card-item-avatars', d.option_avatars);
  }
}

export default DataBinder.create(OrganizeSchema);
