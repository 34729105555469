import { AppConfig } from 'src/configuration';
import { I18n } from 'i18n-js';
import de from 'src/i18n/de.json';
import deCh from 'src/i18n/de-CH.json';
import en from 'src/i18n/en.json';
import en001 from 'src/i18n/en-001.json';
import fr from 'src/i18n/fr.json';
import it from 'src/i18n/it.json';

const { locale } = AppConfig.runtime;

const i18n = new I18n();

// TODO: In the future implement lazy load => https://github.com/fnando/i18n#updating-translation-store
i18n.store(de);
i18n.store(deCh);
i18n.store(en);
i18n.store(en001);
i18n.store(fr);
i18n.store(it);

i18n.locale = locale;
i18n.enableFallback = true;

if (AppConfig.inTestEnv) {
  i18n.missingTranslation = (translation, options) => {
    throw new Error(`missingTranslation i18n.t('${translation}', ${JSON.stringify(options)})`);
  };
}

export default i18n;
