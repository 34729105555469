import morphdom from 'morphdom';

window.App = window.App || { mutation_observers: [] };

export const reloadElement = (selector, { morph = true } = {}) => {
  const element = document.querySelector(selector);
  if (!element) { return; }

  const { pathname, search } = window.location;
  let newSearch;

  if (!search) {
    newSearch = `${search}?x-partial-selector=${selector}`;
  } else {
    newSearch = `${search}&x-partial-selector=${selector}`;
  }

  const url = pathname + newSearch;

  $.ajax({
    url,
    headers: {
      'X-Inline-Form': 1,
      'X-Partial-Selector': selector,
    },
  }).done((response) => {
    if (morph) {
      morphdom(element, response);
    } else {
      const newElement = document.createElement('template');
      newElement.innerHTML = response;
      element.replaceWith(newElement.content.cloneNode(true));
    }
  });
};

window.reloadElement = reloadElement;

// eslint-disable-next-line default-param-last
export const $d = (selector = '', id) => $(`${selector}[data-id="${id}"]`);
